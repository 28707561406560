<template>
  <div>
    <el-divider content-position="left"><span class="title1">数据统计</span></el-divider>
    <el-card  :body-style="{'text-align':'center'}" shadow="never">
      <template v-slot:header class="clearfix">
        <el-form :inline="true" >
          <el-form-item  label="筛选日期：">
            <el-date-picker v-model="date" size="small" @change="getDashboardStatistics" clearable type="daterange" start-placeholder="开始日期" end-placeholder="结束日期"  range-separator="至" :editable="false" />
          </el-form-item>
          <el-form-item  label="供应商：">
            <el-select @change="getDashboardStatistics" size="small" clearable style=" width: 150px" class="filter-item" placeholder="请选择条码供应商" v-model="statisticsCondition.supplierId">
              <el-option v-for="item in suppliers" :key="item.id" :label="item.name" :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>        
        </el-form>
      </template>
      <el-row type="flex" justify="space-around" style="margin-bottom: 20px" :gutter="20">
      <el-col :span="3">
        <div class="box-card">
          <el-row type="flex" justify="space-between">
            <span class="title"><i class="marker total"></i>申请标签数</span>
          </el-row>
          <div class="text"><span class="count total">{{numFormat(statistics.applyCount)}}</span></div>
        </div>
      </el-col>
      <el-col :span="3">
        <div class="box-card">
          <el-row type="flex" justify="space-between">
            <span class="title"><i class="marker primary"></i>采购标签数</span>
          </el-row>
          <div class="text"><span class="count primary">{{numFormat(statistics.orderCount)}}</span></div>
        </div>
      </el-col>
      <el-col :span="3">
        <div class="box-card">
          <el-row type="flex" justify="space-between">
            <span class="title"><i class="marker info"></i>已分发标签数</span>
          </el-row>
          <div class="text"><span class="count info">{{numFormat(statistics.finishDistributeCount)}}</span></div>
        </div>
      </el-col>
      <el-col :span="3">
        <div class="box-card">
          <el-row type="flex" justify="space-between">
            <span class="title"><i class="marker danger"></i>未分发标签数</span>
          </el-row>
          <div class="text"><span class="count danger">{{numFormat(statistics.notDistributeCount)}}</span></div>
        </div>
      </el-col>
      <el-col :span="3">
        <div class="box-card">
          <el-row type="flex" justify="space-between">
            <span class="title"><i class="marker warning"></i>待接收标签数</span>
          </el-row>
          <div class="text"><span class="count warning">{{numFormat(statistics.inAcceptCount)}}</span></div>
        </div>
      </el-col>
      <el-col :span="3">
        <div class="box-card">
          <el-row type="flex" justify="space-between">
            <span class="title"><i class="marker success"></i>已接收标签数</span>
          </el-row>
          <div class="text"><span class="count success">{{numFormat(statistics.finishAcceptCount)}}</span></div>
        </div>
      </el-col>
      <el-col :span="3">
        <div class="box-card">
          <el-row type="flex" justify="space-between">
            <span class="title"><i class="marker gray"></i>拒绝接收标签数</span>
          </el-row>
          <div class="text"><span class="count gray">{{numFormat(statistics.rejectAcceptCount)}}</span></div>
        </div>
      </el-col>

      <!--
      <el-col :span="3">
        <div class="box-card">
          <el-row type="flex" justify="space-between">
            <span class="title"><i class="marker danger"></i>异常设备</span>
          </el-row>
          <div class="text"><span class="count danger">{{failureCount}}</span>台</div>
        </div>
      </el-col> -->
    </el-row>
    </el-card>
    <el-divider content-position="left"><span class="title1">业务办理</span></el-divider>
    <el-row type="flex"  style="margin-bottom: 20px;padding:20px 0 20px;" :gutter="20">
      <el-col :span="3" v-for="(item,index) in cooperationMenus" v-bind:key="index"  style="padding-top:10px;margin-left: 30px;">
        <el-button  v-waves @click="cooperation(item)" style="width:150px">
          <div class="text"><span class="count total">{{item.title}}</span></div>
        </el-button>
      </el-col>
    </el-row>

    <el-divider content-position="left"><span class="title1">我的待办</span></el-divider>
    <el-row style="margin-top: 20px; margin-bottom: 20px;margin-left: 40px;">
      <span style="font-size: 13px;color: #606266;"> 类型：</span>
      <el-radio-group v-model="processDefinitionKey" @change="handleFilter"> 
        <el-radio label="CreateLabel">标签申请</el-radio>
        <el-radio label="CreateOrder">标签采购</el-radio>
      </el-radio-group>
    </el-row>
      <!-- <el-form-item>
        <el-select @change="handleFilter" clearable size="small" style="margin-left: 10px; width: 300px" class="filter-item" placeholder="类型" v-model="processDefinitionKey">
          <el-option v-for="item in keyOptions" :key="item.name" :label="item.name" :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item style="margin-left: 10px;margin-right: 12px;">
        <el-button class="filter-item" size="small" style="margin-left: 10px;" type="primary" v-waves icon="el-icon-search" @click="handleFilter">搜索</el-button>
      </el-form-item> -->
    <el-table :data="tasks" style="width: 100%" border id="labelTable">
      <el-table-column  label="类型" property="processName" align="center">
          <template #default="scope">
            <span>{{scope.row.processName}}</span>
          </template>
      </el-table-column>
      <el-table-column label="申请人" property="processName" align="center">
          <template #default="scope">
            <span>{{scope.row.creatorName}}</span>
          </template>
      </el-table-column>
      <el-table-column label="送办时间" property="sendDate" align="center">
        <template #default="scope">
          <span>{{scope.row.sendDate}}</span>
        </template>
      </el-table-column>
      <el-table-column label="送办人" property="sendAccount" align="center">
        <template #default="scope">
          <span>{{scope.row.sendAccount}}</span>
        </template>
      </el-table-column>
      <el-table-column label="发起部门" align="center" property="name">
          <template  #default="scope">
            <span>{{scope.row.processName && scope.row.processName==='标签申请'?'业务部':'采购部'}}</span>
          </template>
      </el-table-column>
      <el-table-column align="center" label="操作" min-width="150px" class-name="fixed-width">
          <template  #default="scope">
            <el-button type="primary" size="mini" @click="handleShow(scope.row)" v-waves>查看</el-button>
          </template>
        </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import MenuType from '@/utils/menuType'
import waves from '@/directive/waves'
import cooperationApi from '@/apis/cooperation'
import dayjs from 'dayjs'
export default {
  data(){
    return {
      menuType:MenuType,
      menuTemps:[],
      tasks: [],
      suppliers:[],
      statisticsCondition:{
        startTime:null,
        endTime:null,
        supplierId:""
      },
      statistics:{},
      date:[dayjs().subtract(1, 'month'),dayjs()],
      processDefinitionKey: 'CreateLabel',
      keyOptions: [{
          name: '标签申请',
          value: 'CreateLabel'
        },
        {
          name: '标签采购',
          value: 'CreateOrder'
        }]
    }
  },
  computed: {
    ...mapGetters(['sidebar','avatar','menus', 'addRouters']),
    cooperationMenus(){
      this.filterMenus(this.menus,this.menuTemps)
      return this.menuTemps
    }
  },
  created(){
    this.getTasks()
    this.getSuppliers()
    this.getDashboardStatistics()
  },
  methods:{
    cooperation(menu){
      const newWindow = this.$router.resolve({path: menu.children[0].url, params: {id: menu.id}})
      window.open(newWindow.href,'_blank')
    },
    filterMenus(menus,arr) {
      if (Array.isArray(menus) && menus.length > 0) {
        menus.forEach(a => {
          if (a.businessType === 'COOPERATION' && a.type === this.menuType.Expandable ) {
            arr.push(a)
          }
          if(Array.isArray(a.children) && a.children.length > 0){
              this.filterMenus(a.children,arr)
          }
          
        })
      }
    },
    getDashboardStatistics(){
      console.log('DATE',this.date)
      if(this.date){
        this.statisticsCondition.startTime = dayjs(this.date[0]).format('YYYY-MM-DD 00:00:00')
        this.statisticsCondition.endTime = dayjs(this.date[1]).format('YYYY-MM-DD 23:59:59')
      }else{
        this.statisticsCondition.startTime = null
        this.statisticsCondition.endTime = null
      }
      cooperationApi.getDashboardStatistics(this.statisticsCondition).then(resp =>{
        this.statistics = resp.data
      })
    },

    getSuppliers(){
      cooperationApi.getSuppliers().then(resp => this.suppliers = resp.data)
    },
    getTasks(){
      if(this.processDefinitionKey){
          cooperationApi.getTaskForms({processDefinitionKeys:[this.processDefinitionKey]}).then(resp=>{
            this.tasks = resp.data
        })
      }
    },
    handleFilter(value){
      this.processDefinitionKey = value
      if(this.processDefinitionKey){
        cooperationApi.getTaskForms({processDefinitionKeys:[this.processDefinitionKey]}).then(resp=>{
          this.tasks = resp.data
        })
      }
    },
        /**
     * 数字加逗号分隔符
     *
     */
    numFormat (num) {
      if (num) {
        num = num.toString().split('.') // 分隔小数点
      } else {
        return 0
      }
      var arr = num[0].split('').reverse() // 转换成字符数组并且倒序排列
      var res = []
      for (var i = 0, len = arr.length; i < len; i++) {
        if (i % 3 === 0 && i !== 0) {
          res.push(',') // 添加分隔符
        }
        res.push(arr[i])
      }
      res.reverse() // 再次倒序成为正确的顺序
      if (num[1]) {
        // 如果有小数的话添加小数部分
        res = res.join('').concat('.' + num[1])
      } else {
        res = res.join('')
      }
      return res
    },
    handleShow(row){
      if(row.processName && row.processName === '标签申请'){
        //const newWindow = this.$router.resolve({path: '/business/cooperation/apply/todo'})
        const newWindow = this.$router.resolve({ path: "/business/cooperation/apply/todo", query: { id: row.id } });
        window.open(newWindow.href,'_blank')
      }
      if(row.processName && row.processName === '标签采购'){
        //const newWindow = this.$router.resolve({ path: "/business/cooperation/purchase/myBills", query: { id: row.id } });
        const newWindow = this.$router.resolve({path: '/business/cooperation/purchase/myBills'})
        window.open(newWindow.href,'_blank')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .text {
    font-family: SourceHanSansSC-Medium;
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    margin: 5px 0 5px;
  }
  .title1 {
    font-size: 18px;
  }


  $total: #0e0d0d;
  $info: #e08e11;
  $primary: #409eff;
  $success: #67c23a;
  $warning: #098797;
  $danger: #f56c6c;
  $gray: #cac4c4;
  .box-card {
    font-size: 13px;
    padding: 20px 0 10px 10px;
    box-shadow: 0 0 4px 2px rgba(0,0,0,0.1);
    border-radius: 2%;
    .title {
      .marker {
        border-left: 5px solid;
        margin-right: 5px;
        &.total {
          border-left-color: $total;
        }
        &.info {
          border-left-color: $info;
        }
        &.primary {
          border-left-color: $primary;
        }
        &.success {
          border-left-color: $success;
        }
        &.warning {
          border-left-color: $warning;
        }
        &.danger {
          border-left-color: $danger;
        }
        &.gray {
          border-left-color: $gray;
        }
      }
      line-height: 12px;
    }
    .count {
      font-size: 10px;
      margin-right: 10px;
      font-weight: bold;
      &.total {
        color: $total;
      }
      &.info {
        color: $info;
      }      
      &.primary {
        color: $primary;
      }
      &.success {
        color: $success;
      }
      &.warning {
        color: $warning;
      }
      &.danger {
        color: $danger;
      }
      &.gray {
        color: $gray;
      }
    }
    .text {
      text-align: center;
      margin: 10px 0 10px;
    }
  }
</style>
<style>
.el-card__header {
  padding: 10px;
}
.el-form.el-form--inline{
  padding: 0px;
  height: 40px;
}
</style>