import api from "./api";
/**
 * 业务详情相关api
 */
export class CooperationApi extends api {
  getTaskForms(data) {
    return this.request({
      url: `${this.servicePath}/${this.modulePath}/workflow/getTaskForms`,
      method: 'post',
      data
    })
  }

  getDashboardStatistics (condition) {
    return this.request({
      url: `${this.servicePath}/${this.modulePath}/statistics`,
      method: 'post',
      data: condition
    })
  }

  getSuppliers () {
    return this.request({
      url: `${this.servicePath}/${this.modulePath}/suppliers`,
      method: 'get'
    })
  }

}

export default new CooperationApi("cooperationInfo");